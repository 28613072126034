var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-banner" }, [
    _c("div", { attrs: { id: "wheelrandom_com_300x250_sidebar_1" } }),
    _vm._v(" "),
    _c("div", {
      staticStyle: { "margin-top": "10px" },
      attrs: { id: "wheelrandom_com_300x250_sidebar_2" }
    }),
    _vm._v(" "),
    _c("div", { attrs: { id: "sidebar-close" }, on: { click: _vm.close } }, [
      _vm._v("✕")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }