var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showWarning, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showWarning = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.Shareable link")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("sharedialog.If you continue")) +
                      "\n          " +
                      _vm._s(
                        _vm.$t("sharedialog.This link will work for anyone")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "sharedialog.We want this website to be safe place for everyone"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium", type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.enter_UserIsSettingOptions()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("sharedialog.Continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showOptions, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showOptions = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.Shareable link")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("div", { staticClass: "field" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("sharedialog.What should a person be able to do")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "b-radio",
                      {
                        attrs: { "native-value": false },
                        model: {
                          value: _vm.editableWheel,
                          callback: function($$v) {
                            _vm.editableWheel = $$v
                          },
                          expression: "editableWheel"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "sharedialog.They should only be able to spin the wheel"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "b-radio",
                      {
                        attrs: { "native-value": true },
                        model: {
                          value: _vm.editableWheel,
                          callback: function($$v) {
                            _vm.editableWheel = $$v
                          },
                          expression: "editableWheel"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "sharedialog.They should be able to spin the wheel and"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium", type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.enter_CreatingLink()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("sharedialog.Continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.showLink, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.showLink = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-share-alt" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("sharedialog.Shareable link")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("sharedialog.Link to this wheel")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    { staticClass: "column is-8" },
                    [
                      _c("b-input", {
                        attrs: { id: "sharableLinkText" },
                        model: {
                          value: _vm.sharableLink,
                          callback: function($$v) {
                            _vm.sharableLink = $$v
                          },
                          expression: "sharableLink"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-light" },
                          on: { click: _vm.copyLink }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("sharedialog.Copy link")) +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticStyle: { color: "#BBB" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("sharedialog.This link will work for anyone")
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_Inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }