var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("loading-screen", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      }),
      _vm._v(" "),
      _c("toolbar", {
        on: {
          "open-open-dialog": function($event) {
            return _vm.openOpenDialog()
          },
          "open-save-dialog": function($event) {
            return _vm.openSaveDialog()
          },
          "open-share-dialog": function($event) {
            return _vm.openShareDialog()
          },
          "show-snackbar-message": _vm.showSnackbarMessage,
          "reset-wheel": function($event) {
            return _vm.resetWheel()
          },
          "open-customize-dialog": function($event) {
            return _vm.openCustomizeDialog()
          },
          "open-twitter-dialog": function($event) {
            return _vm.openTwitterDialog()
          },
          "open-sheet-dialog": function($event) {
            return _vm.openSheetDialog()
          },
          "set-locale": _vm.setLocale
        }
      }),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "section",
          staticStyle: { "padding-top": "1rem", "padding-bottom": "0" }
        },
        [
          _c(
            "div",
            {
              staticClass: "columns",
              class: { "is-centered": _vm.$store.state.appStatus.fullScreen }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.appStatus.fullScreen,
                      expression: "!$store.state.appStatus.fullScreen"
                    }
                  ],
                  staticClass: "column is-3"
                },
                [_c("sidebarBanner")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "column is-6",
                  class: { "is-7": _vm.$store.state.appStatus.fullScreen }
                },
                [
                  _c("spinningwheel", {
                    ref: "spinningwheel",
                    on: {
                      "wheel-started": _vm.wheelStarted,
                      "name-changed": _vm.nameChanged,
                      "wheel-stopped": _vm.wheelStopped
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.appStatus.fullScreen,
                      expression: "!$store.state.appStatus.fullScreen"
                    }
                  ],
                  staticClass: "column is-3"
                },
                [
                  _c("span", { staticStyle: { "font-family": "Roboto" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("app.Enter names here")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("textbox"),
                  _vm._v(" "),
                  _c("entry-counter"),
                  _vm._v(" "),
                  _c("textboxbuttons")
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "section",
          staticStyle: { "padding-top": "1rem", "padding-bottom": "0" }
        },
        [
          _c(
            "div",
            {
              staticClass: "columns",
              class: { "is-centered": _vm.$store.state.appStatus.fullScreen }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.appStatus.fullScreen,
                      expression: "!$store.state.appStatus.fullScreen"
                    }
                  ],
                  staticClass: "column is-6"
                },
                [_vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2)]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.appStatus.fullScreen,
                      expression: "!$store.state.appStatus.fullScreen"
                    }
                  ],
                  staticClass: "column is-6"
                },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6)
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("opendialog", {
        ref: "opendialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation,
          "auth-error": _vm.authError,
          "reset-wheel-rotation": _vm.resetWheelRotation
        }
      }),
      _vm._v(" "),
      _c("savedialog", {
        ref: "savedialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation,
          "auth-error": _vm.authError
        }
      }),
      _vm._v(" "),
      _c("optionsdialog", {
        ref: "optionsdialog",
        on: { "show-snackbar-message": _vm.showSnackbarMessage }
      }),
      _vm._v(" "),
      _c("sharedialog", {
        ref: "sharedialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation
        }
      }),
      _vm._v(" "),
      _c("twitterdialog", {
        ref: "twitterdialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "start-wait-animation": _vm.startWaitAnimation,
          "stop-wait-animation": _vm.stopWaitAnimation
        }
      }),
      _vm._v(" "),
      _c("sheetdialog", {
        ref: "sheetdialog",
        on: {
          "show-snackbar-message": _vm.showSnackbarMessage,
          "auth-error": _vm.authError
        }
      }),
      _vm._v(" "),
      _c("winnerdialog", {
        ref: "winnerdialog",
        on: {
          "remove-name": _vm.removeName,
          "remove-name-all": _vm.removeNameAll
        }
      }),
      _vm._v(" "),
      _c("winneranimation", { ref: "winneranimation" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("What can I do with WheelRandom?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                  Every day, we hear from folks who are finding new ways to use our website: - In the classroom, use WheelRandom to determine which student will answer the next question. - If you work in retail, spin the wheel to determine who will receive a prize or discount. - When giving a presentation, use the wheel spinner to choose a lucky winner from among those who completed the survey. - At the office, use our wheel to determine who speaks first in your daily standup or management huddle meeting. - Enter your to-do list to WheelRandom and spin it to see which task you should start with if you're feeling overwhelmed. - Put dinner options on the wheel and spin if you can't decide what to cook.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("Is renewable energy used in WheelRandom?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                We are pleased to announce that 100% of the electricity used to power our servers is renewable, with 93 percent coming from carbon-free sources.                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("Is my information secure?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\nWe are dedicated to safeguarding and respecting your privacy and data security. We follow GDPR, CCPA, SB 190, and SB 1392, and we keep a careful eye on any developments. When it comes to data encryption and backups, we follow industry best practices.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("What is the best way to use WheelRandom?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\nWe are dedicated to safeguarding and respecting your privacy and data security. We follow GDPR, CCPA, SB 190, and SB 1392, and we keep a careful eye on any developments. When it comes to data encryption and backups, we follow industry best practices.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("Is my information secure?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\nWe are dedicated to safeguarding and respecting your privacy and data security. We follow GDPR, CCPA, SB 190, and SB 1392, and we keep a careful eye on any developments. When it comes to data encryption and backups, we follow industry best practices.\n                "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("Popular wheels")]),
      _vm._v(" "),
      _c("p", [
        _c("a", { attrs: { href: "/view/covid-vaccine-picker" } }, [
          _vm._v("Which COVID-19 vaccine should I get?")
        ]),
        _c("br"),
        _vm._v(" "),
        _c("a", { attrs: { href: "/view/home-chores-picker" } }, [
          _vm._v("What home chore should I do next?")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-box" }, [
      _c("h2", [_vm._v("Random wheels")]),
      _vm._v(" "),
      _c("p", { attrs: { id: "randomwheels" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }