var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "text-align": "center" } },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$store.state.wheelConfig.title))]),
      _vm._v(" "),
      _c("div", { attrs: { id: "wheelContainer" } }, [
        _c("canvas", {
          staticStyle: { width: "100%" },
          attrs: { id: "wheelCanvas", width: "700", height: "700" },
          on: {
            click: function($event) {
              return _vm.spin()
            }
          }
        }),
        _vm._v(" "),
        !_vm.isTouchScreen
          ? _c(
              "div",
              {
                ref: "instructionsLayer",
                attrs: { id: "instructionsLayer" },
                on: {
                  click: function($event) {
                    return _vm.spin()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "instructionsText",
                    staticStyle: { "padding-top": "13%" },
                    attrs: { id: "topInstruction" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("spinningwheel.Click to spin")) +
                        "\n      "
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isTouchScreen
          ? _c(
              "div",
              {
                ref: "instructionsLayer",
                attrs: { id: "instructionsLayer" },
                on: {
                  click: function($event) {
                    return _vm.spin()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "instructionsText",
                    staticStyle: { "padding-top": "13%" },
                    attrs: { id: "topInstruction" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("spinningwheel.Tap to spin")) +
                        "\n      "
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("p", { attrs: { id: "description" } }, [
        _vm._v(_vm._s(_vm.$store.state.wheelConfig.description))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }