var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.displaySaveDialog, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.displaySaveDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c(
                "header",
                { staticClass: "modal-card-head" },
                [
                  _c("p", { staticClass: "modal-card-title" }, [
                    _c("i", { staticClass: "fa fa-save" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("savedialog.Save wheel")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("profiledropdown", {
                    on: {
                      "log-out": function($event) {
                        return _vm.enter_inactive()
                      },
                      "start-wait-animation": function($event) {
                        return _vm.$emit("start-wait-animation")
                      },
                      "stop-wait-animation": function($event) {
                        return _vm.$emit("stop-wait-animation")
                      },
                      "show-snackbar-message": function(msg) {
                        return _vm.$emit("show-snackbar-message", msg)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("savedialog.Save as")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("b-input", {
                        ref: "saveAsField",
                        attrs: { required: "", maxlength: "100" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.enter_savingWheel($event)
                          }
                        },
                        model: {
                          value: _vm.saveAsName,
                          callback: function($$v) {
                            _vm.saveAsName = $$v
                          },
                          expression: "saveAsName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("savedialog.Your existing wheels")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("savedialog.Select a wheel"),
                            expanded: ""
                          },
                          model: {
                            value: _vm.existingWheelTitle,
                            callback: function($$v) {
                              _vm.existingWheelTitle = $$v
                            },
                            expression: "existingWheelTitle"
                          }
                        },
                        _vm._l(_vm.wheels, function(wheel) {
                          return _c(
                            "option",
                            {
                              key: wheel.title,
                              domProps: { value: wheel.title }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(wheel.title) +
                                  "\n                "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "is-medium",
                        type: "is-primary",
                        disabled: !_vm.saveAsNameIsValid
                      },
                      on: {
                        click: function($event) {
                          return _vm.enter_savingWheel()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("common.Save")) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }