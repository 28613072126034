var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    { attrs: { position: "is-bottom-left", "aria-role": "list" } },
    [
      _c(
        "span",
        {
          staticClass: "button",
          staticStyle: { cursor: "pointer" },
          attrs: { slot: "trigger", role: "button" },
          slot: "trigger"
        },
        [
          _c("img", {
            staticStyle: { height: "30px", "border-radius": "50%" },
            attrs: { src: _vm.$store.state.appStatus.userPhotoUrl }
          }),
          _vm._v("\n     \n    "),
          _c("i", { staticClass: "fas fa-caret-down" })
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { disabled: "", "aria-role": "listitem" } },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("profiledropdown.Signed in as", {
                  name: _vm.$store.state.appStatus.userDisplayName
                })
              ) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { "aria-role": "listitem" },
          on: {
            click: function($event) {
              return _vm.logOut()
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("profiledropdown.Sign out")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { "has-link": "", "aria-role": "listitem" } },
        [
          _c("a", { attrs: { href: "/view-account.html" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("profiledropdown.Export my data")) +
                "\n    "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { "aria-role": "listitem" },
          on: {
            click: function($event) {
              return _vm.confirmAndDelete()
            }
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("profiledropdown.Delete my account")) +
              "\n  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }