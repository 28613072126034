var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "textarea can-go-dark",
    staticStyle: {
      "line-height": "21px",
      overflow: "auto",
      "font-family": "'Roboto', 'Helvetica', 'Arial', sans-serif"
    },
    style:
      "height:" +
      (_vm.$store.state.preferences.appInfoVisible ? "287px" : "287px"),
    attrs: { id: "names", spellcheck: "false", contentEditable: "true" },
    on: {
      paste: _vm.onPaste,
      input: function($event) {
        return _vm.setNames()
      },
      keyup: _vm.IE_setNames
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }