var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card can-go-dark", staticStyle: { "margin-top": "10px" } },
    [
      _c(
        "div",
        { staticClass: "card-content", staticStyle: { padding: "10px" } },
        [
          _c("div", { staticClass: "content" }, [
            _vm.appInfoVisible
              ? _c(
                  "div",
                  [
                    _c("b-button", {
                      staticStyle: { float: "right", "margin-left": "10px" },
                      attrs: {
                        type: "is-light",
                        pack: "fas",
                        "icon-left": "chevron-circle-down",
                        size: "is-small"
                      },
                      on: { click: _vm.toggleVisibility }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("appInfo.The entries you make")) +
                        "\n        "
                    ),
                    _c("hr", { staticStyle: { margin: "5px" } }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#999" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("appInfo.Version")) +
                          " " +
                          _vm._s(_vm.version)
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-button", {
                      staticStyle: { float: "right", "margin-top": "-5px" },
                      attrs: {
                        type: "is-light",
                        pack: "fas",
                        "icon-left": "chevron-circle-up",
                        size: "is-small"
                      },
                      on: { click: _vm.toggleVisibility }
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "#999" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("appInfo.Version")) +
                          " " +
                          _vm._s(_vm.version)
                      )
                    ])
                  ],
                  1
                )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }