/*
Copyright 2020 Google LLC

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    https://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
exports.list = {
  'Abstract': {
    'Anonymous citation': ['#9DC3CA', '#B7D4C6', '#B7D4C6', '#EFECE1', '#EDD5C8', '#F2C0C5'],
    'Black to blue': ['#010101', '#011926', '#003E5C', '#016293', '#007BB8'],
    'Centered truth': ['#775E56', '#E4E1AF', '#FF9263', '#FF6455', '#7C687D'],
    'Cool blues & oranges': ['#3C8BEC', '#7EBEF6', '#FED8B1', '#F88102', '#F95701'],
    'Dead ennui': ['#85A18C', '#F6FDDF', '#FFECCD', '#F2B783'],
    'Information blues': ['#90B0AB', '#CBCAC0', '#DEDAD6', '#5F729A'],
    'Lavender to dark blue': ['#FFBAFF', '#DE87FF', '#A455FF', '#681DFF', '#1000CA', '#000098'],
    'Offbeat recognition': ['#F1647A', '#F3F1DB', '#74CBCC', '#27ABB2', '#C1D765', '#80C544'],
    'Office hours': ['#DE7373', '#F0DC9F', '#DEBB89', '#7787AE', '#43487B'],
    'Party fun': ['#891180', '#EA27A2', '#FB9390', '#F6C892', '#FFF5BD', '#FDFFDE'],
    'Pastel tones': ['#E0BBE4', '#957DAD', '#D291BC', '#FEC8D8', '#FFDFD3'],
    'Perfect feminine': ['#C09BE3', '#F6E0C7', '#F0C589', '#EA9A5D', '#EA6D63', '#E33F64'],
    'Slumber party': ['#278DF0', '#FFEF93', '#FF96E1', '#9B78FC'],
    'Teasing questions': ['#C75589', '#E369A4', '#F5CC22', '#F6EF2D', '#B2E792', '#82D07B'],
    'Turquoise to blue': ['#00FEEF', '#09EBEE', '#19CEEB', '#28ACEA', '#388EE9', '#3D76E0'],
  },
  'Brands': {
    'Asana': ['#3be8b0', '#1aafd0', '#6a67ce', '#ffb900', '#fc636b'],
    'Baidu': ['#de0f17', '#2529d8'],
    'Barclays': ['#00aeef', '#00395d'],
    'Booking.com': ['#003580', '#009fe3', '#feba02', '#666666', '#f2f6fa'],
    'British Airways': ['#075aaa', '#eb2226', '#01295c', '#efe9e5', '#aca095', '#b9cfed'],
    'Burger King': ['#ec1c24', '#fdbd10', '#0066b2', '#ed7902'],
    'CNN': ['#cc0000', '#000000', '#282828', '#464646'],
    'Delta Airlines': ['#c8102e', '#862633', '#003a70'],
    'Discord': ['#7289da', '#99aab5', '#2c2f33', '#23272a'],
    'Dropbox': ['#007ee5', '#7b8994', '#47525d', '#3d464d'],
    'Drupal': ['#0077c0', '#81ceff', '#00598e'],
    'E*TRADE': ['#6633cc', '#99cc00'],
    'Fedex': ['#4d148c', '#ff6600'],
    'Firefox': ['#e66000', '#ff9500', '#ffcb00', '#00539f', '#0095dd', '#331e54'],
    'Google': ['#3369E8', '#D50F25', '#EEB211', '#009925'],
    'Google Earth logo': ['#F5F5F5', '#C4E1FF', '#91BFFF', '#4285F4', '#255FDB'],
    'Google Maps': ['#D5D8DB', '#E8E8E8', '#C3ECB2', '#AADAFF', '#FFF2AF', '#F6CF65'],
    'Google Sheets UI': ['#00A568', '#85D1B2', '#F8F9FA', '#C2C3C5', '#5F6368'],
    'Heineken': ['#008200', '#205527', '#ff2b00', '#c3c3c3'],
    'Heroku': ['#c9c3e6', '#6762a6'],
    'IKEA': ['#ffcc00', '#003399'],
    'LEGO': ['#f6ec35', '#d11013', '#000000'],
    'LinkedIn': ['#0077b5', '#000000', '#313335', '#86888a', '#caccce', '#00a0dc'],
    'London Underground': ['#000099', '#cc3333'],
    'Lowe\'s': ['#004990', '#15b6e5'],
    'Lyft': ['#ff00bf', '#352384', '#333447', '#f3f3f5'],
    'McDonald\'s': ['#bf0c0c', '#e76a05', '#ffc600', '#47bc00', '#05007b', '#9748a8'],
    'Microsoft': ['#f65314', '#7cbb00', '#00a1f1', '#ffbb00'],
    'MIT': ['#a31f34', '#8a8b8c', '#c2c0bf'],
    'Mozilla': ['#c13832', '#d7d3c8', '#4d4e53', '#', '#', '#'],
    'NASA': ['#fc3d21', '#0b3d91'],
    'NASCAR': ['#ffd659', '#e4002b', '#007ac2'],
    'National Geographic': ['#ffcc00', '#000000'],
    'NBC': ['#e1ac26', '#dc380f', '#9f0812', '#6347b2', '#368dd5', '#70af1e'],
    'Nest': ['#00afd8', '#7b858e'],
    'Netflix': ['#e50914', '#221f1f'],
    'Oracle': ['#ff0000', '#000000', '#7f7f7f'],
    'Pizza Hut': ['#ee3124', '#00a160', '#ffc425'],
    'Polaroid': ['#00a3e2', '#1ba548', '#fdc800', '#f1860e', '#e41b13'],
    'Python': ['#ffde57', '#4584b6', '#646464'],
    'Reddit': ['#ff4500', '#5f99cf', '#cee3f8'],
    'Residence Inn': ['#b32317', '#f58025', '#3a6e8f', '#87b2d8', '#8d8b00', '#c1d72f'],
    'Ritz-Carlton': ['#006b95', '#4f5b65', '#b3812a', '#a01a1f'],
    'Rolls-Royce': ['#680021', '#fffaec', '#939598', '#000000'],
    'Ryanair': ['#073590', '#f1c933', '#2091eb'],
    'Salesforce': ['#1798c1', '#ff1100'],
    'Slack': ['#6ecadc', '#e9a820', '#e01563', '#3eb991'],
    'Spotify': ['#1db954', '#ffffff', '#000000'],
    'Stack Exchange': ['#1e5397', '#376db6', '#4ca2da', '#91d8f4'],
    'Staples': ['#cc0000', '#2c8aec', '#ffcc00'],
    'Subway': ['#489e3b', '#fabd42', '#cd0a20'],
    'Twitch': ['#6441a5', '#b9a3e3', '#262626', '#f1f1f1'],
    'Twitter': ['#1da1f2', '#14171a', '#657786', '#aab8c2', '#e1e8ed', '#f5f8fa'],
    'Uber': ['#09091a', '#c0c0c8', '#1fbad6'],
    'Ubuntu': ['#dd4814', '#77216f', '#5e2750', '#2c001e', '#aea79f', '#333333'],
    'Volvo': ['#003057', '#115740', '#65665c', '#425563', '#517891', '#212721'],
    'Walgreens': ['#e31837', '#f37520', '#489cd4', '#2774a6', '#35393d'],
    'Wikimedia': ['#339966', '#0063bf', '#990000'],
    'Zapier': ['#ff4a00', '#fd7622', '#ffc43e', '#5f6c72', '#499df3', '#13d0ab'],
  },
  'Default': {
    'Default': ['#3369E8', '#D50F25', '#EEB211', '#009925'],
  },
  'Flags': {
    'Australia': ['#00008B', '#FFFFFF', '#FF0000'],
    'Belgium': ['#000000', '#FDDA24', '#EF3340'],
    'Canada': ['#FF0000', '#FFFFFF'],
    'Denmark': ['#C60C30', '#FFFFFF'],
    'Finland': ['#002F6C', '#FFFFFF'],
    'France': ['#0055A4', '#FFFFFF', '#EF4135'],
    'Germany': ['#000000', '#DD0000', '#FFCE00'],
    'Hong Kong': ['#DE2408', '#FFFFFF'],
    'India': ['#FF9933', '#FFFFFF', '#138808', '#000080'],
    'Indonesia': ['#FF0000', '#FFFFFF'],
    'Ireland': ['#169B62', '#FFFFFF', '#FF883E'],
    'Malaysia': ['#010066', '#CC0001', '#FFFFFF', '#FFCC00'],
    'Mexico': ['#006341', '#FFFFFF', '#CE1126'],
    'Netherlands': ['#AE1C28', '#FFFFFF', '#21468B'],
    'New Zealand': ['#00247D', '#FFFFFF', '#CC142B'],
    'Norway': ['#C8102E', '#FFFFFF', '#003087'],
    'Philippines': ['#FCD116', '#0038A8', '#CE1126', '#FFFFFF'],
    'Singapore': ['#EF3340', '#FFFFFF'],
    'Spain': ['#AA151B', '#F1BF00'],
    'Sweden': ['#004B87', '#FFCD00'],
    'Thailand': ['#A51931', '#F4F5F8', '#2D2A4A'],
    'United Arab Emirates': ['#FF0000', '#00732F', '#FFFFFF', '#000000'],
    'United Kingdom': ['#00247D', '#FFFFFF', '#CF142B'],
    'United States': ['#3C3B6E', '#FFFFFF', '#B22234'],
    'Vietnam': ['#DA251D', '#FFCD00'],
  },
  'Games': {
    'Candy Crush': ['#D98121', '#EDB23F', '#F5D346', '#F7E1B4', '#FAF4DC', '#D3151C'],
    'Candyland': ['#fbd7e7', '#eeddec', '#e1e3f1', '#d4e9f6', '#c7effb'],
    'Fortnite': ['#E98F5C', '#B448F0', '#2AC9FA', '#F9E36E', '#C7C9D6'],
    'Mario & Luigi': ['#5a98e1', '#139334', '#cea900', '#fe130f', '#095cd4', '#16b65c'],
    'Minecraft': ['#477A1E', '#70B237', '#8FCA5C', '#61371F', '#854F2B', '#C28340'],
    'Monopoly': ['#C70000', '#BFDBAE', '#8FBC72', '#FFFFFF', '#D7BAAA'],
    'Overwatch': ['#f99e1a', '#43484c', '#405275', '#218ffe', '#000000'],
    'Pikachu': ['#FAD61D', '#E19720', '#F62D14', '#811E09', '#000000'],
    'Rocket League': ['#008BFF', '#FF8B00'],
    'The Crew 2': ['#FFDB15', '#FB8018', '#FB1257', '#01CDF9'],
  },
  'Holidays': {
    'Christmas': ['#348228', '#469A47', '#DAF7FF', '#FFFAFA', '#EB2029', '#D70816'],
    'Earth Day': ['#11205B', '#E9E9E9', '#8F705E', '#BF9269', '#F0C951', '#3C6F36'],
    'Easter': ['#367D83', '#BADBD2', '#F47A97', '#F3EB9A', '#E9C05F'],
    'Halloween': ['#F36A1F', '#F3861F', '#000000'],
    'New Year': ['#FFDF00', '#FFCC00', '#ECBD00', '#CC9900', '#B8860B'],
    'Saint Patrick\'s Day': ['#008001', '#228B22', '#00AD43'],
    'Thanksgiving': ['#FDC149', '#E7A755', '#873826', '#61782A', '#D64F06', '#BBB300'],
  },
  'Nature': {
    'Antarctica evening': ['#F4D3C4', '#F2AEBB', '#D895DA', '#A091D6', '#696FC7', '#A7AAE1'],
    'Beach sunset': ['#3C47C6', '#656CDE', '#DE6CC8', '#F8A091', '#F7E392', '#F7A55D'],
    'Desert scene': ['#FAD381', '#D79F62', '#2E4647', '#518D6A', '#9BC692'],
    'Evening sky': ['#001F38', '#526079', '#9A7E8E', '#E3757F', '#FD997F', '#FFD0AA'],
    'Fresh meadow': ['#84A013', '#A8BB2E', '#D4DA5E', '#EBEF90', '#FBFDBC', '#FAC703'],
    'Fruit tones': ['#E9692C', '#ED9121', '#FFC324', '#FFF000', '#66B447', '#8EE53F'],
    'Giraffe': ['#F0ECE1', '#EDCF8F', '#C97F4E', '#6F4A38', '#977359'],
    'Jellyfish': ['#3EA1B6', '#0E6B8C', '#133855', '#6B669E', '#BB90C8', '#EFD8EC'],
    'Jungle': ['#135E46', '#478966', '#73A788', '#E3C6AD', '#D09D7B', '#B67B65'],
    'Koi fish': ['#F16323', '#F2F3F4', '#FFD021', '#E34427'],
    'Monsoon': ['#01A8CA', '#32D1EC', '#F1F1F1', '#AFDFF3'],
    'Moon': ['#31302E', '#94908D', '#DAD9D7', '#F0F0F0', '#C3C2BE'],
    'Purple horizon': ['#b7b8f9', '#3a1f8a', '#2c1357', '#462867', '#593b6a'],
    'Rainbow': ['#5E02E9', '#3C70EF', '#30D800', '#E7E200', '#FD8B00', '#F20800'],
    'Red desert': ['#99857A', '#C67B5C', '#E27B58', '#FF9D6F', '#663926', '#8E6A5A'],
    'Red sunset': ['#761000', '#C10900', '#E92100', '#FFDB53', '#FFA93D', '#FF7A29'],
    'Sandy beach': ['#9FE2BF', '#FFE5C6', '#EFCDB4', '#4BC7CF', '#5CF5FF'],
    'Sun': ['#FFFFFF', '#FFE484', '#FFCC33', '#FC9601', '#D14009'],
    'Underwater': ['#4F42B5', '#82E1E3', '#D4F1F9', '#E3FFFA', '#4CC395'],
    'Water lilies': ['#448036', '#5C9550', '#FBBA37', '#EE6BA4', '#F192B5', '#F4B0C7'],
  },
  'Seasons': {
    'Fall green': ['#529106', '#61A307', '#86B71B', '#B9BD00', '#8EA202', '#799203'],
    'Fall leaves': ['#BA4634', '#D85C4E', '#EAA250', '#F5DD8B', '#CEC218', '#5F7818'],
    'Fall road': ['#A05A48', '#563633', '#6D463C', '#D6BD9D', '#D19E6D', '#C57D56'],
    'Fall yellow': ['#DC7C00', '#FF9705', '#FEB20A', '#FFCB00', '#FEDF05'],
    'Spring gentle': ['#C3D4C1', '#FFFFE5', '#B4D2A4', '#95BC83', '#F6EAD3'],
    'Spring green beauty': ['#F99825', '#F5C527', '#CFFA6B', '#9EEC1C', '#89DF42', '#75CB00'],
    'Spring pastels': ['#94DE8B', '#B19CD9', '#F4A8CF', '#F4C3D7', '#FDFD96', '#B6E7B9'],
    'Spring pink': ['#E25157', '#F5808F', '#FFACCC', '#FFCBDF', '#EBF0EA', '#93C64E'],
    'Summer buzz': ['#3BAED5', '#5CC8E2', '#FDE683', '#E66C1E', '#D52210'],
    'Summer carnival': ['#01A7EC', '#FFFF46', '#FFC94B', '#FE8F5D', '#FE47B3', '#80DA65'],
    'Summer is hot': ['#BF221C', '#E8681F', '#FBC44F', '#FFE67F', '#FDFFD2', '#F9F500'],
    'Summer pool party': ['#0198F1', '#49C2FF', '#A9EEFF', '#0067D4', '#E999DE', '#7C62C4'],
    'Winter blues': ['#2377A4', '#50A3C6', '#79C0D7', '#F8F8F8', '#DDDFDF', '#C2C2C2'],
    'Winter camouflage': ['#7C7C7C', '#0E1317', '#ACDEF8', '#344A78', '#FDFAFC', '#D6D6D6'],
    'Winter growth': ['#383159', '#656DA6', '#C5E1F2', '#52734F'],
    'Winter wonderland': ['#23644D', '#D3F1F3', '#FFFFFF', '#E22A45', '#C7102E'],
  },
}
